@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.box-11 {
  display: flex;
  margin-top: 30px;

  .box-13 {
    flex: 1;
    border-radius: 5px;
    background-color: $fff;

    .chart-title_03 {
      display: inline-flex;
      align-items: center;

      .line-03 {
        display: inline-flex;
        align-items: flex-end;
        margin-right: 30px;
        margin-top: 20px;
        padding-left: 15px;

        &:last-child { margin-right: 0; }
        strong {
          @include mont-font();
          font-size: 20px;
          font-weight: 400;
          line-height: 1;
          color: $primary;
        }
        span {
          margin-left: 10px;
          font-size: 12px;
          line-height: 1;
          color: $c87;
        }
      }
    }
  }
}

.box-150 {
  display: inline-flex;
  flex-direction: column;
  width: 350px;
  margin-left: 10px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: $fff;

  strong {
    font-size: 12px;
    font-weight: 600;
    color: $c55;
  }
  .box-151 {
    display: inline-flex;
    flex-direction: column;

    p {
      display: inline-flex;
      // align-items: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      color: $c55;

      .ico-11 {
        height: 24px;
        width: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      b {
        display: inline-flex;
        width: 4px;
        height: 4px;
        margin-right: 6px;
        border-radius: 2px;
        background-color: $c55;
      }
    }
  }
}

// 模拟数据
.mock-100 {
  background-image: url(https://file.smartsalary.cn/web-img%402.0/market/image/ismock.png);
  background-repeat: no-repeat;
  background-position: center center;
}

.tag-01 {
  @include secondary-bg(0.1);
  @include secondary-color(1);
  display: inline-flex;
  justify-content: center;
  height: 20px;
  padding: 0 6px;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 3px;
}
.tag-02 {
  @include primary-bg(0.1);
  @include primary-color(1);
  display: inline-flex;
  justify-content: center;
  height: 20px;
  padding: 0 6px;
  margin: 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 3px;
}

.one-1303 {
  display: inline-flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 1;

  span {
    &:first-child {
      display: inline-flex;
      align-items: center;
    }
    &:last-child { margin-top: 6px; }
  }
}

.box-30top {
  margin-top: 10px;
}
.tab-11 {
  margin-top: 20px;
}
