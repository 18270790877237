@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.city-different-pdf_3030 {
  height: 100%;
  display: flex;
  flex-direction: column;

  .different-content_3030 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .title-1001 {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
  }
  .content-3030 {
    flex: 1;
    display: flex;
    flex-direction: column;

    .desc-3030 {
      display: inline-flex;
      flex-direction: column;
      margin-top: 10px;

      span { font-size: 12px; }
    }
  }

  .city-different_3030 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px 10px 16px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: $fff;

    .line-015 {
      display: inline-flex;
      align-items: flex-end;

      .text-11 {
        margin-left: 10px;
        font-size: 12px;
        line-height: 1;
        color: $c87;
      }
    }
  }

  .content-10177 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .content-1021 {
      flex: 1;
      display: flex;
      flex-direction: column;

      .line-61 {
        display: inline-flex;

        span {
          // flex: 1;
          height: 36px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          border-left: 1px solid $ec;
          border-top: 1px solid $ec;
          border-bottom: 1px solid $ec;
          color: $c55;
          background-color: $f7;

          &:first-child { width: 80px; }
          &:not(:first-child) { flex: 1; }
          &:last-child { border-right: 1px solid $ec; }
        }
      }
      .line-51 {
        width: 100%;
        display: inline-flex;

        .span-11 {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid $ec;
          border-bottom: 1px solid $ec;

          &:first-child { width: 80px; }
          &:not(:first-child) { flex: 1; }
        }
        .span-13 {
          flex: 1;
          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid $ec;
          border-left: 1px solid $ec;

          &:last-child { border-right: 1px solid $ec; }
        }
        .span-133 {
          @include mont-font();
        }
        .line-111 {
          flex: 1;
          display: inline-flex;
          flex-direction: column;
        }
        .line-53 {
          display: inline-flex;
          width: 100%;
        }
        .up-111 {
          color: $error;

          i { margin-right: 10px; }
        }
        .down-111 {
          color: $success;

          i {
            visibility: hidden;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
