@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.human-common {
  background-color: $white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  .tabs {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .time-tabs {
    margin-top: 20px;
  }
  .chart-detail {
    width: 410px;
    padding: 25px 20px;
    background-color: $f7;
    border-radius: 5px;
    margin-top: 5px;
    overflow-y: auto;
    .item {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
      b {
        display: block;
        width: 6px;
        height: 6px;
        background-color: $f7;
        border: 1px solid $cf;
        margin-right: 5px;
        border-radius: 50%;
        margin-top: 8px;
      }
      p {
        color: $c55;
        flex: 1;
        .t-red {
          color: $error;
          background-color: transparent;
        }
        .t-yellow {
          color: $secondary;
          background-color: transparent;
        }
        .t-blue {
          color: $success;
          background-color: transparent;
        }
        .category {
          @include primary-bg(0.3);
          color: $primary;
          border-radius: 3px;
          padding: 0 2px;
          margin: 0 3px;
        }
        span {
          @include mont-font();
          color: $c55;
          font-weight: 500;
        }
        .change_ratio {
          margin-left: 2px;
        }
      }
    }
  }
}
