@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.box-15050 {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: $fff;

  strong {
    font-size: 12px;
    font-weight: 600;
    color: $c55;
  }
  .box-151 {
    display: inline-flex;
    flex-direction: column;

    p {
      display: inline-flex;
      // align-items: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      color: $c55;

      .ico-11 {
        height: 24px;
        width: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      b {
        display: inline-flex;
        width: 4px;
        height: 4px;
        margin-right: 6px;
        border-radius: 2px;
        background-color: $c55;
      }
    }
  }
}
