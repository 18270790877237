@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-page {
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  .effect-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-50 {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: $darkBg;

      .title-51 {
        display: inline-flex;
        flex-direction: column;
        max-width: 1920px;
        min-width: 1280px;
        width: 100%;
        height: 200px;
        padding: 30px 60px;
        position: relative;
        .img-10306 {
          width: 300px;
          height: 148px;
          cursor: pointer;
          position: absolute;
          right: 40px;
          top: 10px;
          z-index: 99;
          .img-1122 {
            width: 100%;
            height: 100%;
          }
          svg {
            position: absolute;
            bottom: 20px;
            left: 20px;
          }
        }
        .title-5121 {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          h3 {
            font-size: 24px;
            font-weight: 600;
            color: $white;
          }
          p {
            font-size: 12px;
            font-weight: 500;
            color: $white;
            opacity: 0.5;
            margin-left: 20px;
          }
        }
        .title-5623 {
          font-size: 12px;
          font-weight: 500;
          color: $white;
          opacity: 0.5;
          margin-top: 30px;
        }
      }
    }
    .effect-content {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 1920px;
      min-width: 1280px;
      width: 100%;
      padding: 170px 40px 50px 40px;

      .effect-111 {
        position: relative;
        width: 100%;
        margin-top: 20px;
        padding-left: 240px;
      }
    }
  }
}

.box-1001 {
  min-height: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  .label-111 {
    @include black-color(0.65);
    margin-bottom: 20px;
  }
}

.effect-pdf-export {
  width: 100px;
  height: 39px;
  border-radius: 5px;
  border: 1px solid $primary;
  cursor: pointer;
  font-weight: 600;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
}
