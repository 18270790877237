@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.pdf-page-100 {
  width: 595px;
  height: 843px;
  margin-top: 20px;
  background-color: $f7;
  padding: 24px 40px;
  position: relative;
  overflow: hidden;
  .water-img{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}
