@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.fresh-salary-table_101 {
  display: flex;
  flex-direction: column;

  .content-101 {
    display: inline-flex;
  }
}
