@import "./color.scss";

* {
  box-sizing: border-box;
  font-family: "PingFang SC Regular", "PingFang SC Medium", "Microsoft YaHei", system-ui, sans-serif;
  outline-width: 0;
  outline-style: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  // -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  // color: $greyDark;
  background-color: $bodyBg;
  // -webkit-overflow-scrolling: touch;
  // -webkit-user-select: none;
  // -webkit-app-region: drag;
}

::selection {
  color: $bodyBg;
  background-color: $primary;
}
