@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.support-effect_10 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.effect-10 {
  .content-255 {
    display: flex;
    flex-direction: column;

    .line-301 {

    }
    .line-302 {
      margin-top: 10px;
      font-size: 12px;
      color: $c55;
    }
  }
}

.content-6699 {
  display: flex;
  margin-top: 30px;

  .content-66990 {
    flex: 1;
    display: flex;
    flex-direction: column;

    .content-30 {
      display: flex;
      align-items: center;
      margin-top: 30px;

      &:first-child { margin-top: 0; }
      strong {
        display: inline-flex;
        width: 72px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .content-31 {
      flex: 1;
      display: inline-flex;
      flex-direction: column;
      // margin-left: 26px;
    }
    .content-33 {
      flex: 1;
      display: inline-flex;

      &:first-child { padding-top: 0; }
      .content-3301 {
        display: inline-flex;
        flex-direction: column;
        width: 146px;
      }
      .content-33011 {
        width: 130px;
      }
      .line-3301 {
        display: inline-flex;
        align-items: center;
        padding: 2px;
        margin-right: 10px;
        border-radius: 2px;
        background-color: $f7;

        span {
          font-size: 12px;
          font-weight: 600;
          color: $c55;
        }
        .digit-11 { @include mont-font(); }
      }
      .place-3301 {
        display: inline-flex;
        height: 16px;
        width: 100%;
      }
      .content-3303 {
        align-items: flex-end;
        border-right: 1px solid $ec;
      }
      .content-3305 {
        flex: 1;
        justify-content: center;
      }
      .line-3302 {
        display: inline-flex;
        align-items: center;
        padding-left: 10px;

        .icon-111 {
          display: inline-flex;
          align-items: center;
        }
        .icon-115 {
          svg { margin-right: 4px; }
        }
      }
    }
  }
}
