@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.budget-page-warp {
  .budget-top-pic {
    display: flex;
    justify-content: space-between;
    .welfare-budget {
      width: calc(50% - 5px);
      background-color: $f7;
      padding: 20px 10px 10px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .budget-svg {
        border-radius: 8px;
        background-color: $fff;
        margin-top: 20px;
        height: 238px;
        width: 100%;
      }
    }
    .welfare-budget-warp {
      width: calc(50% - 5px);
      background-color: $f7;
      padding: 20px 10px 10px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
    }
    .sub-title {
      @include black-color(0.85);
      font-size: 16px;
      font-weight: 600;
    }
  }

  .budget-city-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    border-radius: 8px;
    margin-top: 20px;
    .sub-title {
      @include black-color(0.85);
      font-size: 16px;
      font-weight: 600;
    }
    .budget-pic-group {
      display: flex;
      justify-content: space-between;
      position: relative;
      height: 550px;
      background-color: $fff;
      border-radius: 8px;
      margin-top: 20px;
      img {
        position: absolute;
        left: 0;
        top: 40%;
      }

      .welfare-city-map-0091 {
        display: flex;
        width: calc(100% - 330px);
        height: 520px;
        border-radius: 8px;
        div {
          border-radius: 8px;
        }
        canvas {
          border-radius: 8px;
        }

        #map-091 {
          width: 100%;
          height: 100%;
        }

        .title2 {
          position: absolute;
          top: 30px;
          left: 30px;
        }

        .city-area_0091 {
          position: absolute;
          display: flex;
          flex-direction: column;
          right: 0;
          top: 0;
          height: 100%;
          width: 330px;

          .list2 {
            display: flex;
          }
        }
      }
      .welfare-top-ranking-001 {
        @include primary-bg(1);
        width: 300px;
        height: 438px;
        padding: 20px 10px;
        overflow: hidden;
        border-radius: 8px 8px 0 0;
        position: absolute;
        right: 30px;
        bottom: 0;
        .map-top-ranking-content-005 {
          display: flex;
          justify-content: space-between;
          color: $white;
          padding: 7px 5px 6px;
          cursor: pointer;
          .map-item-008 {
            @include white-color(0.85);
            display: block;
            white-space: nowrap;
          }
          .up-item-009 {
            @include mont-font;
            width: 60px;
            display: block;
            text-align: right;
          }
          .up-item-002 {
            @include mont-font;
            width: 60px;
            display: block;
          }
          .svg-xinzhi-logo {
            margin-right: 5px;
          }
          .map-top-progress-001 {
            width: 120px;
            height: 8px;
            padding: 6px 0;
            .MuiLinearProgress-root {
              height: 8px;
              border-radius: 4px;
              span {
                &:nth-child(2) {
                  @include white-bg(1);
                  height: 8px;
                  width: 100px;
                  border-radius: 4px;
                }
                &:nth-child(1) {
                  margin-top: 3px;
                  display: none;
                }
              }
            }
          }
        }
        .map-top-ranking-content-008 {
          @include white-bg(1);
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          color: $white;
          padding: 7px 5px 6px;
          cursor: pointer;
          .map-item-008 {
            color: $black;
            white-space: nowrap;
          }
          .svg-xinzhi-logo {
            margin-right: 5px;
          }
          .up-item-009 {
            @include mont-font;
            width: 60px;
            display: block;
            text-align: right;
          }
          .up-item-002 {
            @include mont-font;
            width: 60px;
            display: block;
          }
          .map-top-progress-001 {
            width: 120px;
            height: 8px;
            padding: 6px 0;
            .MuiLinearProgress-root {
              height: 8px;
              border-radius: 4px;
              span {
                &:nth-child(2) {
                  background: $primary;
                  height: 8px;
                  width: 100px;
                  border-radius: 4px;
                }
                &:nth-child(1) {
                  margin-top: 3px;
                  display: none;
                }
              }
            }
          }
          .map-item-008 {
            color: $black;
            white-space: nowrap;
          }
          span {
            color: $black;
          }
          .css-20vwwh-MuiLinearProgress-bar1 {
            background: $primary;
          }
        }
        .sub-title,
        .sub-title01 {
          color: $white;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          padding-top: 4px;
          display: inline;
          border-top: 2px solid $secondary;
          margin-left: 5px;
        }
        > p {
          @include white-color(0.5);
          font-size: 12px;
          margin-top: 5px;
          margin-bottom: 10px;
          padding: 0 5px;
        }
      }
    }
  }

  .budget-warp-project {
    background-color: $f7;
    padding: 20px 10px 10px;
    border-radius: 8px;
    margin-top: 20px;
    .welfare-budget-hobby {
      .sub-title {
        @include black-color(0.85);
        font-size: 16px;
        font-weight: 600;
        background-color: $f7;
        display: block;
      }
      .two-title {
        background-color: $f7;
        color: $c87;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 12px;
        &:nth-child(4) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .budget-warp-protrait {
    background-color: $f7;
    padding: 20px 10px 10px;
    border-radius: 8px;
    margin-top: 20px;
    .welfare-portrait-warp {
      .sub-title {
        background-color: $f7;
        @include black-color(0.85);
        font-size: 16px;
        font-weight: 600;
        display: block;
      }
      p {
        background-color: $f7;
        color: $c87;
      }
      .portrait-item-003 {
        border-radius: 8px;
      }
    }
  }
  .budget-warp-holiday {
    background-color: $f7;
    padding: 20px 10px 10px;
    border-radius: 8px;
    margin-top: 20px;
    .average-budget-pic {
      .sub-title {
        background-color: $f7;
        @include black-color(0.85);
        font-size: 16px;
        font-weight: 600;
        display: block;
      }
      .average-salary-item-02 {
        background-color: $fff;
        border-radius: 8px;
        margin-top: 20px;
        padding: 20px;
      }
    }
  }
}
