@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.distribution-rate_10 {

  .tab-111 {
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }

  .content-05010 {
    display: flex;
    margin: 30px 0;

    .content-05015 {
      flex: 1;
      display: flex;
      flex-direction: column;

      b.title-001 {
        @include black-color(1);
        margin-bottom: 10px;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      .box-001 {
        width: 100%;
        height: 52px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        border-radius: 5px;
        background-color: $f7;

        .line-001 {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;

          b {
            font-size: 12px;
            font-weight: 500;
            color: $c55;
          }
        }
        .line-111 {
          position: relative;
          width: 100%;
          height: 6px;
          border-radius: 4px;
          background: linear-gradient(270deg, $error 0%, $secondary 47%, $success 100%);

          span {
            // width: 100%;
            display: inline-flex;
            align-items: center;
            // justify-content: center;
            padding: 0 30px;
          }
          b {
            width: 0;
            height: 0;
            border-top: 10px solid $primary;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
          }
        }
        .line-002 {
          position: relative;
          display: inline-flex;
          align-items: center;
          margin-top: 4px;

          b, span, strong {
            display: inline-flex;
            height: 8px;
            border-radius: 2px;
          }
          b {
            // width: 40%;
            background-color: $success;
          }
          span {
            // width: 10%;
            padding: 0 2px;
          }
          i {
            width: 100%;
            height: 100%;
            display: inline-flex;
            border-radius: 2px;
            background-color: $d9;
          }
          strong {
            // width: 50%;
            background-color: $error;
          }
        }
        .line-222 {
          position: relative;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          span {
            display: inline-flex;
            align-items: center;

            i {
              @include mont-font();
              position: relative;
              top: 1px;
              font-size: 12px;
              line-height: 1;
              font-weight: 600;
              color: $c55;
            }
            strong {
              padding: 1px 6px;
              margin-left: 4px;
              white-space: nowrap;
              font-size: 12px;
              line-height: 1;
              font-weight: 500;
              border-radius: 2px;
            }
            &:first-child {
              strong {
                @include success-bg(0.1);
                border: 1px solid $success;
                color: $success;
              }
            }
            &:nth-child(2) {
              strong {
                @include primary-color(1);
                @include primary-bg(0.1);
                border: 1px solid $primary;
              }
            }
            &:last-child {
              strong {
                @include error-color(1);
                @include error-bg(0.1);
                border: 1px solid $error;
              }
            }
          }
        }
      }
      .box-002 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 160px;
        margin-top: 10px;

        .section-03 {
          display: inline-flex;
          border-bottom: 1px solid $d8;

          .item-002 {
            flex: 1;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            b {
              @include mont-font();
              margin-bottom: 3px;
              font-weight: 500;
              font-size: 14px;
              line-height: 1;
              color: $d9;
            }
            strong {
              display: inline-flex;
              width: 20px;
              // height: 100px;
              border-radius: 2px;
              background-color: $d9;
            }
          }
          .down-002 {
            b { color: $success; }
            strong { background-color: $success; }
          }
          .up-002 {
            b { color: $error; }
            strong { background-color: $error; }
          }
        }
        .section-05 {
          .item-002 {
            b { color: $primary; }
            strong { background-color: $primary; }
          }
        }
        .section-02 {
          display: inline-flex;
          margin-top: 4px;

          span {
            flex: 1;
            display: inline-flex;
            justify-content: center;
            @include mont-font();
            @include black-color(0.85);
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
          }
        }
      }
    }
    .content-05016 {
      padding-right: 5px;
      border-right: 1px solid $ec;
    }
    .content-05017 {
      padding-left: 5px;
    }
  }
}
