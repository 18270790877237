@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.welfare-city-picker_0059 {
  @include white-bg(1);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    border-color: $primary;
  }
  &:hover .picker_00591 i {
    @include primary-color(1);
  }
  .picker_00591 {
    display: inline-flex;
    align-items: center;
    padding-left: 12px;
    i {
      @include black-color(0.5);
      line-height: 1.2;
    }
    p {
      display: inline-flex;
      align-items: center;
      margin-left: 12px;
      padding: 4px 8px;
      border-radius: 4px;
      line-height: 1;
      background: rgba(0, 0, 0, 0.08);

      span {
        @include black-color(0.85);
      }
      svg {
        margin-left: 8px;
      }
    }
  }
}
