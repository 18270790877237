@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-time-warp {
  .chart-demo {
    display: flex;
    margin-top: 30px;
    .industry-chart-warp {
      height: 400px;
      flex: 1;
    }
    .industry-detail {
      height: 335px;
    }
  }
}
