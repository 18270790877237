@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.fresh-table_10101 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .fresh-head_10101 {
    display: flex;
    justify-content: space-between;
    height: 46px;
    padding-bottom: 2px;
    border-bottom: 1px solid $ccc;

    .line-fresh_01 {
      display: inline-flex;
      flex-direction: column;
    }
    .line-fresh_03 {
      display: inline-flex;
    }
    .line-fresh_01, .line-fresh_03 {
      span {
        height: 21px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1;
      }
    }
    .line-fresh_001 {
      height: 100%;
      span {
        height: 100%;
        border-radius: 4px;
        color: $fff;
        background-color: $c999;
      }
    }
    .line-fresh_001, .line-fresh_002 {
      span {
        width: 100%;
        color: $fff;
        background-color: $cf;
      }
      .line-fresh_03 {
        margin-top: 1px;
        span { border-radius: 0 0 4px 4px; }
      }
    }
    .line-fresh_001 {
      span { background-color: $c999; }
      &>span { border-radius: 4px; }
    }
    .line-fresh_002 {
      &>span { border-radius: 4px 4px 0 0; }
    }
    .line-fresh_001, .line-fresh_002 { width: 48px; }
    .line-fresh_002 { margin-left: 1px; }
    .line-fresh_003, .line-fresh_004, .line-fresh_005 {
      flex: 1;
      margin-left: 1px;

      &>span { border-radius: 4px 4px 0 0; }
      span {
        color: $fff;
      }
      .line-fresh_03 {
        width: 100%;
        margin-top: 1px;
        justify-content: space-between;

        span {
          display: inline-flex;
          margin-left: 1px;
        }
        span:first-child {
          width: 38px;
          border-bottom-left-radius: 4px;
          margin-left: 0;
        }
        span:not(:first-child) { flex: 1; }
        span:last-child { border-bottom-right-radius: 4px; }
      }
    }
    .line-fresh_003 {
      span { background-color: $secondary; }
    }
    .line-fresh_004 {
      span { background-color: $primary; }
    }
    .line-fresh_005 {
      span { background-color: $t3Two; }
    }
  }

  .fresh-content_10101 {
    display: flex;
    flex-direction: column;

    .line-fresh_11 {
      height: 22px;
      margin-top: 1px;
      display: inline-flex;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 1px;
        font-size: 12px;
        background-color: $fff;

        &.span-100 {
          margin-left: 0;
          color: $fff;
          background-color: $c999;
        }
        &.span-100, &.span-101 { width: 48px; }
        &.span-101, &.span-105 {
          @include mont-font();
          font-weight: 600;
          color: #181C25;
        }
        &.span-1002, &.span-1005, &.span-1008 { width: 38px; }
        &.span-1003, &.span-1004, &.span-1006,
        &.span-1007, &.span-1009, &.span-10010 { flex: 1 }
      }
      &:first-child { span { border-radius: 4px 4px 0 0; }}
      &:last-child { span { border-radius: 0 0 4px 4px; }}
    }
    .line-fresh_101 {
      margin-top: 8px;
    }
  }
}
