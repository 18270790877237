@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.fresh-salary_101 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-101101 {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
  .title-101101 {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .box-101101 {
    display: flex;
    flex-direction: column;
    margin-top: 6px;

    .box-13 {
      flex: 1;
      border-radius: 5px;
      background-color: $fff;

      .chart-title_03 {
        display: inline-flex;
        align-items: center;
        padding-right: 10px;

        .line-03 {
          display: inline-flex;
          align-items: flex-end;
          margin-right: 10px;
          margin-top: 10px;
          padding-left: 10px;

          &:last-child { margin-right: 0; }
          strong {
            @include mont-font();
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            color: $primary;
          }
          span {
            margin-left: 6px;
            font-size: 12px;
            line-height: 1.1;
            color: $c87;
          }
        }
      }
    }
  }

  .up-rate_101101 {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    border-radius: 10px;
    // background-color: $fff;

    .one-11 {
      display: inline-flex;

      strong {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
      }
    }
    .one-13 {
      font-size: 12px;
      line-height: 1;
    }
    .one-166 {
      display: inline-flex;
      flex-direction: column;

      span {
        line-height: 1.5;
        color: #878787;
        margin-top: 5px;
      }
    }
  }
}
