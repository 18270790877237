@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.hot-job_1515 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-1515 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .title-1515 {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
  }
}

.hot-job_1616 {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
