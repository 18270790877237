@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.output-top10-warp {
  .chart-demo {
    display: flex;
    margin-top: 30px;
    .chart {
      flex: 1;
      margin-right: 10px;
      .output-top10-warp {
        display: flex;
        justify-content: space-between;
        height: 420px;
        .output-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .show-bar {
            flex: 1;
            position: relative;
            margin: 5px 0;
            width: 36px;
            height: 100%;
            .show-number {
              // position: absolute;
              width: 36px;
              // bottom: 0;
              // left: 0;
              font-weight: 600;
              color: $secondary;
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              align-items: center;
              height: calc(100% - 36px);
              i {
                @include mont-font();
              }
              b {
                display: block;
                width: 36px;
                background: linear-gradient(180deg, $fad9 0%, $f76b 100%);
                border-radius: 4px 4px 0 0;
                margin-top: 5px;
              }
              .changeColor {
                background: linear-gradient(180deg, $d6fa 0%, $c389 100%);
              }
            }
            .picwarps {
              background-color: $f76b;
              height: 36px;
              border-radius: 4px 4px 100px 100px;
            }
            span {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
              position: absolute;
              left: 0;
              bottom: 0;
              background-color: $white;
              background-repeat: no-repeat;
              background-size: 26px auto;
              background-position: center center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .company {
            font-size: 16px;
            font-weight: 600;
            color: $black;
          }
          .show-rate {
            margin-top: 5px;
            display: flex;
            align-items: center;
            font-weight: 600;
            span {
              @include mont-font();
            }
            .up {
              transform: rotate(90deg);
              display: block;
            }
            .down {
              transform: rotate(-90deg);
              display: block;
            }
          }
        }
      }
    }
    .output-detail {
      width: 220px;
      height: 415px;
      padding: 25px 20px;
      background-color: $f7;
      border-radius: 5px;
      overflow-y: auto;
      p {
        font-weight: 600;
        color: $black;
      }
      .out {
        margin-top: 40px;
      }
      .item {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        align-items: center;
        i {
          width: 26px;
          height: 26px;
          display: block;
          background-color: $white;
          background-repeat: no-repeat;
          background-size: 20px auto;
          background-position: center center;
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }
        span {
          font-size: 12px;
          font-weight: 600;
          color: $c55;
          margin-left: 10px;
        }
      }
    }
  }
}
