@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.foot-pdf-warp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    font-size: 12px;
    color: $ccc;
    width: 26px;
    justify-content: center;
  }
  b {
    display: flex;
    height: 1px;
    background-color: $ccc;
  }
  .right-br {
    width: 40px;
  }
  .left-br {
    flex: 1;
    margin-left: 10px;
  }
  .foot-logo{
    color: $ccc;
  }
}
