@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.staff-size-warp {
  .chart-demo {
    display: flex;
    margin-top: 30px;
    .chart {
      flex: 1;
      margin-right: 10px;
      .staff-chart {
        height: 340px;
      }
    }
    .staff-detail {
      height: 275px;
    }
  }
}
