@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.popover-11011 {
  display: flex;
  height: 708px;
  width: 700px;
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 555;

  .one-11011 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    width: 268px;
    height: 100%;
    padding-top: 8px;
    // margin-top: 8px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .bottom-111 {
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
  }

  .two-11011 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 100%;
    padding: 16px;
    margin-left: -8px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .title-111 {
      padding: 10px 0 16px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
    .two-11013 {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;

      .line-161 {
        @include black-bg(0.04);
        @include black-color(0.65);
        padding: 12px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 20px;
      }
      .line-163 {
        display: inline-flex;
        flex-direction: column;

        b {
          @include black-color(0.25);
          font-size: 12px;
        }
        .line-165 {
          display: inline-block;
          margin-left: -30px;
          margin-top: -4px;

          img {
            margin-left: 30px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
