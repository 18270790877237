@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.index-pdf-head {
  color: $white;
  border-bottom: 1px solid $ccc;
  height: 22px;
  width: 515px;
  display: flex;
  justify-content: space-between;
  .content {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $ccc;
    line-height: 22px;
    p {
      padding: 0 12px;
      font-size: 12px;
    }
    b {
      display: block;
      width: 1px;
      height: 10px;
      background-color: $white;
    }
  }
  .information {
    color: $black;
  }
}
