@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-industry-contrast-warp {
  .chart-demo {
    display: flex;
    margin-top: 30px;
    .industry-chart-warp {
      height: 400px;
      flex: 1;
    }
    .industry-detail {
      height: 335px;
    }
    .industry-income-warp {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: $black;
        line-height: 22px;
        padding-bottom: 8px;
        border-bottom: 1px solid $ec;
      }
      .left-chart {
        width: 60%;
      }
      .right-chart {
        width: calc(40% - 10px);
      }
    }
  }
}
