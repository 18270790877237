@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.in-common-table-warp-all {
  padding: 10px;
  border-radius: 8px;
  background-color: $fff;
  .in-common-table-warp-copy {
    display: flex;
    justify-content: flex-start;
    .left {
      display: flex;
      justify-content: flex-start;
      p {
        display: flex;
        height: 36px;
        align-items: center;
        padding-left: 10px;
        border-radius: 3px 0 0 3px;
        justify-content: center;
        &:nth-child(odd) {
          background-color: $f7;
        }
        &:nth-child(1) {
          background-color: $f7;
          font-weight: 500;
        }
        &:first-child {
          font-size: 13px;
          word-break: break-all;
          height: 72px;
          border-right: 2px solid $white;
        }
      }
    }
    .right-warp {
      overflow-x: auto;
      flex: 1;
      overflow-y: hidden;
      .item-top {
        height: 36px;
        display: flex;
        justify-content: flex-start;
        p {
          font-weight: 500;
          @include primary-bg(0.1);
          text-align: center;
          line-height: 36px;
          &:nth-child(1) {
            border-right: 2px solid $f7;
            border-bottom: 2px solid $f7;
          }
          &:nth-child(2) {
            border-bottom: 2px solid $f7;
          }
        }
      }
      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 36px;
        align-items: center;
        padding-right: 10px;
        border-radius: 0 3px 3px 0;
        &:nth-child(even) {
          background-color: $f7;
        }
        &:nth-child(2) {
          @include primary-bg(0.1);
          width: 100%;
          padding-right: 0;
          p {
            border-right: 2px solid $f7;
            height: 100%;
            line-height: 36px;
            &:nth-child(8) {
              border-right: 0;
              padding-right: 10px;
            }
          }
        }
        &:first-child {
          font-size: 13px;
          word-break: break-all;
        }
        p {
          text-align: center;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .in-common-table-warp-copys {
    display: flex;
    justify-content: flex-start;
    height: 360px;
    overflow: hidden;
    overflow-y: auto;
    .left {
      display: flex;
      justify-content: flex-start;
      p {
        display: flex;
        height: 36px;
        align-items: center;
        padding-left: 10px;
        border-radius: 3px 0 0 3px;
        justify-content: center;
        &:nth-child(even) {
          background-color: $f7;
        }
      }
    }
    .right-warp {
      flex: 1;
      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 36px;
        align-items: center;
        padding-right: 10px;
        border-radius: 0 3px 3px 0;
        &:nth-child(odd) {
          background-color: $f7;
        }
        &:nth-child(1) {
          @include primary-bg(0.1);
          font-weight: 500;
        }
        &:first-child {
          font-size: 13px;
          word-break: break-all;
        }
        p {
          @include mont-font;
          text-align: center;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}
