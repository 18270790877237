// 引入 iconfont 字体
* {
  margin: 0;
  padding: 0;
}
.icon {
  width: 16px;
  height: 16px;
  // vertical-align: -0.15em;
  /* stylelint-disable-next-line value-keyword-case */
  fill: currentColor;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100vh;
}

.size12 {
  font-size: 12px;
}
.size13 {
  font-size: 13px;
}
.size14 {
  font-size: 14px;
}
.size15 {
  font-size: 15px;
}
.size16 {
  font-size: 16px;
}
.size17 {
  font-size: 17px;
}
.size18 {
  font-size: 18px;
}
.size19 {
  font-size: 19px;
}
.size20 {
  font-size: 20px;
}

.public-echarts {
  width: calc(100vw - 813px);
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}

.content-all-middle {
  width: 100%;
  min-width: 1280px;
  max-width: 1920px;
}
