@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.time-trend_10 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: $f7;

  .box-11 {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .one-11 {
      display: inline-flex;

      strong {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
      }
    }
  }

  .box-13 {
    display: flex;
    margin-top: 30px;

    .box-15 {
      flex: 1;
      padding: 20px 10px 10px 10px;
      border-radius: 5px;
      background-color: $fff;

      .chart-title_03 {
        display: inline-flex;
        align-items: center;

        .line-03 {
          display: inline-flex;
          align-items: flex-end;
          margin-right: 30px;
          margin-top: 20px;
          padding-left: 15px;

          &:last-child { margin-right: 0; }
          strong {
            @include mont-font();
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            color: $primary;
          }
          span {
            margin-left: 10px;
            font-size: 12px;
            line-height: 1;
            color: $c87;
          }
        }
      }
    }
    .box-16 {
      width: 350px;
      margin-left: 10px;
      border-radius: 5px;
      background-color: $fff;
    }
  }
}

.one-166 {
  display: inline-flex;
  flex-direction: column;

  span {
    line-height: 1.5;
    color: #878787;
    margin-top: 5px;
  }
}
