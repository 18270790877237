@import './color.scss';

// div, section, ul, ol, li, p,
// h1, h2, h3, h4, h5, h6 {
//   margin: 0;
//   padding: 0;
// }

// div, section, ul, ol {
//   display: flex;
// }

// li, p {
//   display: inline-flex;
//   align-items: flex-start;
// }

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

ul, ol { list-style: none; }

video, img {
  &:focus { outline: none; }
}

i { font-style: normal; }
b, strong { font-weight: 400; }
a {
  text-decoration: none;
  color: $C000;
}
hr {
  margin-top: 8px;
  margin-bottom: 8px;
}

menu { margin: 0; padding: 0; }

:focus-visible, :focus {
  outline: none;
}
