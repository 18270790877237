@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.in-common-table-warp {
  padding: 10px;
  border-radius: 8px;
  background-color: $fff;
  display: flex;
  justify-content: flex-start;
  .left {
    display: flex;
    justify-content: flex-start;
    p {
      display: flex;
      height: 36px;
      align-items: center;
      padding-left: 10px;
      border-radius: 3px 0 0 3px;
      &:nth-child(odd) {
        background-color: $f7;
      }
      &:nth-child(1) {
        @include primary-bg(0.1);
        font-weight: 500;
      }
      &:first-child {
        font-size: 13px;
        word-break: break-all;
      }
    }
  }
  .right-warp {
    overflow-x: auto;
    flex: 1;
    overflow-y: hidden;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 36px;
      align-items: center;
      padding-right: 10px;
      border-radius: 0 3px 3px 0;
      &:nth-child(odd) {
        background-color: $f7;
      }
      &:nth-child(1) {
        @include primary-bg(0.1);
        font-weight: 500;
      }
      &:first-child {
        font-size: 13px;
        word-break: break-all;
      }
      p {
        @include mont-font;
        text-align: center;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
