@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.desc-1616 {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  .line-106106 {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    margin-top: 5px;

    span, b {
      font-size: 12px;
      line-height: 1;
    }
    span { color: $c999; }
    b { color: $c333; }
  }
}
