@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.hobby-page-warp {
  .hobby-exchange-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    border-radius: 8px;
    .welfare-rank {
      border-radius: 8px;
      position: relative;
      .welfare-rank-top-detail {
        p {
          color: $c87;
          font-size: 12px;
          margin-top: 8px;
        }
      }
      .welfare-rank-top {
        display: flex;
        justify-content: space-between;
        background-color: $f7;
        align-items: center;

        .rank-005 {
          display: inline-flex;
        }
        .sub-title {
          @include black-color(0.85);
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          padding-top: 4px;
        }
        .info-006 {
          @include black-color(0.25);
          display: inline-flex;
          padding-top: 6px;
          font-size: 12px;
        }
      }
      .welfare-rank-content {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        @include white-bg(1);
        border-radius: 8px;
        .welfare-rank-mid {
          @include primary-bg(0.02);
          width: 30%;
          margin: 40px 20px 0 0;
          padding: 0 20px;
          border-radius: 8px;
        }
        .welfare-rank-mid-con {
          margin-top: 20px;
          p {
            @include black-color(0.85);
            display: flex;
            justify-content: space-between;
            font-weight: 600;
          }
        }
        .welfare-rank-right {
          @include black-bg(0.02);
          width: 25%;
          margin: 40px 0 0;
          padding: 0 20px 20px;
          border-radius: 8px;
          .welfare-rank-right-title {
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
            .sub-title {
              @include black-color(0.85);
              font-size: 16px;
              font-weight: 600;
              line-height: 1;
              padding-top: 4px;
              display: inline;
            }
            p {
              @include black-bg(0.08);
              margin-top: 2px;
              margin-left: 8px;
              padding: 2px;
              font-size: 12px;
              font-weight: 600;
            }
          }
          .welfare-rank-right-list {
            @include bottom-boder(0.04);
            @include black-color(0.65);
            padding-bottom: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .content {
              margin-left: 15px;
            }
            p {
              width: 54px;
              height: 28px;
              display: flex;
              justify-content: space-between;
              line-height: 28px;
            }
          }
        }
      }
      .welfare-rank-line {
        @include black-bg(0.08);
        width: 100%;
        height: 2px;
        margin: 30px 0;
      }
      .welfare-echange-city {
        margin-top: 20px;
        position: relative;
        .echange-pic {
          background-color: $fff;
          border-radius: 8px;
          margin-top: 20px;
        }
        .sub-title {
          @include black-color(0.85);
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          padding-top: 4px;
        }
      }
    }
  }
  .welfare-festival-rank {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    position: relative;
    .time-change-01 {
      display: flex;
      justify-content: center;
      > div {
        background-color: $fff;
        border-radius: 8px;
      }
    }
    .welfare-rank-top {
      display: flex;
      justify-content: space-between;
      .sub-title {
        @include black-color(0.85);
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        padding-top: 4px;
        display: inline;
      }
    }
    .welfare-rank-content {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      @include white-bg(1);
      border-radius: 8px;
      margin-bottom: 20px;
      .welfare-rank-mid {
        @include primary-bg(0.02);
        width: 30%;
        margin: 40px 20px 0 0;
        padding: 0 20px;
      }
      .welfare-rank-mid-con {
        margin-top: 20px;
        p {
          @include black-color(0.85);
          display: flex;
          justify-content: space-between;
          font-weight: 600;
        }
      }
      .welfare-rank-right {
        @include black-bg(0.02);
        width: 25%;
        margin: 40px 0 0;
        padding: 0 20px;
        .welfare-rank-right-title {
          display: flex;
          justify-content: flex-start;
          margin-top: 20px;
          .sub-title {
            @include black-color(0.85);
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            padding-top: 4px;
          }
          p {
            @include black-bg(0.08);
            margin-top: 2px;
            margin-left: 8px;
            padding: 2px;
            font-size: 12px;
            font-weight: 600;
          }
        }
        .welfare-rank-right-list {
          @include bottom-boder(0.04);
          @include black-color(0.65);
          padding-bottom: 15px;
          margin-top: 15px;
          display: flex;
          justify-content: flex-start;
          .content {
            margin-left: 15px;
          }
          p {
            width: 54px;
            height: 28px;
            display: flex;
            justify-content: space-between;
            line-height: 28px;
          }
        }
      }
    }
    .welfare-rank-line {
      @include black-bg(0.08);
      width: 100%;
      height: 2px;
      margin: 30px 0;
    }
    .welfare-echange-city {
      position: relative;
      .sub-title {
        @include black-color(0.85);
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        padding-top: 4px;
      }
    }
  }
}
