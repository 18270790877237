@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.pdf-page-static-top {
  position: relative;
  .water-img{
    position: absolute;
    left: 0;
    top: 0;
  }
  .main-page {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    .main-page-content {
      position: relative;
      height: 540px;
      .bg-main {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
      .title-contents {
        position: absolute;
        left: 0;
        top: 210px;
        margin-bottom: 10px;
        width: 330px;
        padding: 5px;
        border: 8px solid $primary;
        .top {
          display: flex;
          justify-content: space-between;
          border-bottom: 4px solid $primary;
          p {
            &:nth-child(1) {
              @include mont-font;
              font-weight: 600;
              font-size: 78px;
              color: $primary;
              height: 78px;
              line-height: 78px;
            }
            &:nth-child(2) {
              font-weight: 600;
              font-size: 18px;
              color: $primary;
              display: flex;
              align-items: center;
              line-height: 39px;
              width: 108px;
              margin-top: 3px;
            }
          }
        }
        .mid {
          display: flex;
          justify-content: flex-start;
          margin-top: 10px;
          img {
            width: 74px;
            height: 24px;
            margin-right: 10px;
          }
          p {
            &:nth-child(2) {
              background-color: $primary;
              font-size: 16px;
              color: $white;
              font-weight: 600;
              line-height: 24px;
              padding: 0 6px;
            }
            &:nth-child(3) {
              border: 1px solid $primary;
              line-height: 22px;
              font-size: 16px;
              color: $primary;
              font-weight: 600;
              line-height: 24px;
              padding: 0 6px;
            }
          }
        }
        .bot {
          display: flex;
          justify-content: space-between;
          height: 24px;
          margin-top: 10px;
          p {
            @include mont-font;
            @include primary-color(0.5);
            border-left: 1px solid $primary;
            font-size: 12px;
            display: flex;
            align-items: flex-end;
            font-weight: 600;
            padding: 0 5px;
          }
          img {
            height: 24px;
            &:nth-child(1) {
              width: 90px;
              margin-right: 5px;
            }
            &:nth-child(3) {
              width: 97px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.pdf-page-static {
  position: relative;
  .water-img{
    position: absolute;
    left: 0;
    top: 0;
  }
  .list-page {
    display: flex;
    justify-content: flex-end;
    .list-page-title {
      height: 250px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: right;
      width: 160px;
      p {
        font-size: 60px;
        font-weight: 600;
        color: $c33;
        line-height: 60px;
        margin-bottom: 4px;
        padding-bottom: 24px;
        border-bottom: 4px solid $c33;
      }
      img {
        width: 160px;
        height: 16px;
      }
    }
  }
  .list-page-item {
    .item-warps {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;
      .one-title {
        .top {
          width: 324px;
          display: flex;
          justify-content: space-between;
          b {
            font-size: 16px;
            font-weight: 600;
            color: $c33;
            line-height: 16px;
            &:nth-child(2) {
              @include mont-font;
              font-size: 14px;
            }
          }
        }
        .bot {
          width: 324px;
          display: flex;
          justify-content: flex-start;
          margin-top: 3px;
          b {
            @include c33-bg(1);
            height: 1px;
            display: block;
            width: 12px;
          }
          em {
            @include c33-bg(0.5);
            height: 1px;
            display: block;
            width: 12px;
          }
          i {
            background-color: $ccc;
            height: 1px;
            display: block;
            width: 300px;
          }
        }
      }
      .two-title {
        display: flex;
        justify-content: space-between;
        width: 300px;
        padding-bottom: 3px;
        border-bottom: 1px solid $ccc;
        b {
          font-size: 16px;
          color: $c33;
          line-height: 16px;
          &:nth-child(2) {
            @include mont-font;
            font-size: 14px;
          }
        }
      }
    }
  }
}
