@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.salary-up-pdf_1010 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-salary-1001 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .title-1001 {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
  }
}

.salary-up_1010 {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  border-radius: 10px;
  // background-color: $fff;

  .one-11 {
    display: inline-flex;

    strong {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .one-13 {
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
  }
  .one-166 {
    display: inline-flex;
    flex-direction: column;

    span {
      line-height: 1.5;
      color: #878787;
      margin-top: 5px;
    }
  }

  .salary-up-box-1100 {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .box-13 {
      flex: 1;
      border-radius: 5px;
      background-color: $fff;

      .chart-title_03 {
        display: inline-flex;
        align-items: center;
        padding-right: 10px;

        .line-03 {
          display: inline-flex;
          align-items: flex-end;
          margin-right: 0;
          margin-top: 15px;
          padding-left: 8px;

          &:last-child { margin-right: 0; }
          strong {
            @include mont-font();
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            color: $primary;
          }
          span {
            margin-left: 5px;
            font-size: 12px;
            line-height: 1.1;
            color: $c87;
          }
        }
      }
    }
  }
}

// 模拟数据
.mock-100 {
  background-image: url(https://file.smartsalary.cn/web-img%402.0/market/image/ismock.png);
  background-repeat: no-repeat;
  background-position: center center;
}
