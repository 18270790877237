@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.menu-60 {
  @include white-bg(1);
  display: flex;
  height: 100px;
  padding: 20px 20px 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  .section-0101 {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    max-width: 200px;
    margin-right: 30px;

    b {
      @include black-color(0.65);
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
    }
    .line-001 {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
