@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.category-type-rate_10 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  background-color: $f7;

  .one-11 {
    display: inline-flex;

    strong {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .one-1300 {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
    color: $c87;
  }

  .one-13 {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
  }
  .one-166 {
    display: inline-flex;
    flex-direction: column;

    span {
      line-height: 1.5;
      color: #878787;
      margin-top: 5px;
    }
  }
  .box-166 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px 20px 10px;

    .title-166 {
      width: 100%;
      display: inline-flex;
      align-items: center;
      height: 36px;
      border-radius: 5px 5px 0 0;
      background-color: $f7;

      span {
        display: inline-flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        color: $c55;

        &:first-child { width: 100px; }
        &:not(:first-child) { flex: 1; }
      }
    }

    .table-166 {
      display: inline-flex;
      flex-direction: column;

      .line-166 {
        height: 50px;
        display: inline-flex;
        align-items: center;
        border-bottom: 1px solid $ec;

        .value-10 {
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          font-size: 13px;
          font-weight: 500;
          color: $c55;
        }
        .value-11 {
          width: 100px;
          line-height: 1.2;
          border-right: 1px solid $ec;
        }
        .value-13 {
          flex: 1;
          flex-direction: column;
          align-items: flex-start;
          border-right: 1px solid $ec;

          &:last-child { border-right: none; }
          span {
            @include mont-font();
            margin-bottom: 4px;
            font-size: 13px;
            font-weight: 400;
            line-height: 1;
          }
          i {
            display: inline-flex;
            height: 10px;
            border-radius: 2px;
            width: 10%;
          }
          i.up-15 { @include error-bg(1); }
          i.down-15 { @include success-bg(1); }
        }
      }
    }
  }
}
