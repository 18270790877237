@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.city-different_10 {
  display: flex;
  flex-direction: column;
  padding: 30px 0;

  .content-1016 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px 10px 10px 10px;
    border-radius: 10px;
    background-color: $f7;

    .one-11 {
      display: inline-flex;

      strong {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
      }
    }
    .one-1300 {
      margin-top: 15px;
      margin-left: 5px;
      font-size: 12px;
      line-height: 1.5;
      color: $c87;
    }
    .one-13 {
      margin-top: 15px;
      margin-left: 5px;
      font-size: 12px;
      line-height: 1;
    }
    .one-166 {
      display: inline-flex;
      flex-direction: column;

      span {
        line-height: 1.5;
        color: #878787;
        margin-top: 5px;
      }
    }
    .box-150 {
      margin-top: 20px;
    }
  }

  .content-1015 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: $fff;

    .line-015 {
      display: inline-flex;
      align-items: flex-end;

      .text-11 {
        margin-left: 10px;
        font-size: 12px;
        line-height: 1;
        color: $c87;
      }
    }
  }

  .content-1017 {
    width: 100%;
    display: flex;
    margin-top: 20px;

    .content-1021 {
      flex: 1;
      display: flex;
      flex-direction: column;

      &:last-child { margin-left: 30px; }
      .line-61 {
        display: inline-flex;

        span {
          flex: 1;
          height: 36px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          border-left: 1px solid $ec;
          border-top: 1px solid $ec;
          border-bottom: 1px solid $ec;
          color: $c55;
          background-color: $f7;

          &:last-child { border-right: 1px solid $ec; }
        }
      }
      .line-51 {
        width: 100%;
        display: inline-flex;

        .span-11 {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 25%;
          border-left: 1px solid $ec;
          border-bottom: 1px solid $ec;
        }
        .span-13 {
          flex: 1;
          height: 60px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid $ec;
          border-left: 1px solid $ec;

          &:last-child { border-right: 1px solid $ec; }
        }
        .span-133 {
          @include mont-font();
        }
        .line-111 {
          flex: 1;
          display: inline-flex;
          flex-direction: column;
        }
        .line-53 {
          display: inline-flex;
          width: 100%;
        }
        .up-111 {
          color: $error;

          i { margin-right: 10px; }
        }
        .down-111 {
          color: $success;

          i {
            visibility: hidden;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
