@font-face {
  font-family: "DIN Alternate";
  // src: url("../font/DIN\ Alternate\ Bold.ttf");
  src: url("https://file.smartsalary.cn/fonts/DIN%20Alternate%20Bold.ttf");
}

@font-face {
  font-family: "PingFangSC Regular";
  // src: url("../font/PingFang-SC-Regular.ttf");
  src: url("https://file.smartsalary.cn/fonts/PingFang-SC-Regular.ttf");
}

@font-face {
   @include mont-font();
  src: url('../fonts/DIN\ Condensed\ Bold.ttf');
}

@font-face {
  font-family: "Roboto";
  src: url("https://file.smartsalary.cn/fonts/Roboto/Roboto-Bold.ttf");
}
