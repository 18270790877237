@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";
@import "./var.scss";

.table-1616 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .content-1616 {
    display: flex;
    flex-direction: column;
    margin-top: 4px;

    .content-160 {
      display: flex;
      margin-top: 4px;

      .industry-1011 {
        width: $industryWidth;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1.3;
        border-radius: 4px 0 0 4px;
        color: $fff;
        background-color: $c999;
      }
    }

    .content-170 {
      flex: 1;
      display: inline-flex;
      flex-direction: column;
    }

    .content-190 {
      flex: 1;
      display: inline-flex;

      .category-1011, .position-1011 {
        min-height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 1px;
        font-size: 12px;
        line-height: 1.3;
      }
      .category-1011 {
        width: $categoryWidth;
        background-color: $f6;
      }
      .position-1011 {
        width: $positionWidth;
        background-color: $e6;
      }
      &:not(:first-child) { margin-top: 1px; }
    }

    .line-166166 {
      flex: 1;
      display: inline-flex;

      .line-166 {
        display: inline-flex;
        flex: 1;
        margin-left: 1px;
      }
      i {
        @include mont-font();
        flex: 1;
        // width: $valueWidth;
        min-height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-left: 1px;
        font-size: 12px;
        line-height: 1;
      }
      .value-11 { margin-left: 0; }
      .value-11, .value-13 {
        background-color: $f6;
      }
      .value-12 {
        background-color: $e6;
      }
    }
  }
}
