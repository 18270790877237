@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.top-011 {
  display: flex;
  justify-content: center;
  height: 260px;
  padding: 40px;
  background-image: url(https://file.smartsalary.cn/web-img/home/dashboard-index.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  .top-0110 {
    flex: 1;
    min-width: 1200px;
    max-width: 1840px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .setting-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: -90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 110px;
      color: $c55;
    }
  }
  .box-001 {
    @include white-bg(0.8);
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 10px;

    .section-001 {
      flex: 1;
      position: relative;
      display: inline-flex;
      margin-left: 30px;

      .avatar-111 {
        position: relative;
        top: -10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        border: 2px solid $fff;
        border-radius: 50%;
        background-color: $secondary;
      }

      img {
        position: relative;
        top: -10px;
        width: 80px;
        height: 80px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        border: 2px solid $fff;
        border-radius: 50%;
      }

      .line-001 {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15px;
        padding-top: 15px;

        .intro-001 {
          margin-top: 8px;
          font-size: 14px;
          line-height: 1;
          color: $c87;
        }
      }
      .line-003 {
        display: inline-flex;
        flex-direction: column;
        margin-left: 15px;
        padding-top: 13px;

        strong {
          @include black-color(1);
          margin-right: 10px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1;
        }
        .line-0033 {
          display: inline-flex;
          align-items: flex-end;
        }
        .line-330 {
          display: inline-flex;
          margin-top: 12px;

          .line-331 {
            display: inline-flex;
            align-items: center;
            padding: 5px 20px;
            border-right: 1px solid $cf;
            cursor: pointer;

            span {
              @include primary-color(1);
              margin-left: 10px;
              line-height: 1;
            }
          }
          .line-3313 {
            padding-right: 0;
            border-right: none;
          }
          .line-3310 {
            padding-left: 0;
          }
        }
      }
    }

    .section-002 {
      @include white-bg(1);
      display: inline-flex;
      align-items: center;
      height: 60px;
      width: 100%;
      padding: 0 56px 0 30px;
      border-radius: 0 0 10px 10px;

      .desc-001 {
        flex: 1;
        display: inline-flex;
        align-items: center;
        overflow: hidden;

        .desc-003 {
          display: inline-flex;
          white-space: nowrap;

          b {
            @include secondary-color(1);
            margin-left: 5px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
          }
        }
        .desc-005 {
          flex: 1;
          display: inline-flex;
          margin-left: 10px;
          line-height: 1;
          overflow: hidden;

          span {
            cursor: pointer;
          }
          .p-005 {
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .login-001 { width: 60%; }
  .nlogin-001 { width: 100%; }

  .box-002 {
    @include white-bg(0.8);
    width: calc(40% - 12px);
    display: flex;
    flex-direction: column;
    padding: 20px 20px 15px 20px;
    border-radius: 10px;

    strong {
      font-size: 16px;
      font-weight: 600;
    }
    .line-111 {
      display: inline-flex;
    }
    .line-113 {
      flex: 1;
      display: inline-flex;
      align-items: flex-end;

      .line-1131 {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
      }
      .text-1100 {
        position: relative;
        line-height: 1;
        font-size: 16px;
        color: $c55;

        b {
          position: relative;
          top: 1px;
          line-height: 1;
          margin: 0 5px;
          font-size: 24px;
          font-weight: 600;
          color: $secondary;
        }
      }
      .process-1100 {
        position: relative;
        display: inline-flex;
        width: 100%;
        height: 5px;
        margin-top: 15px;
        border-radius: 4px;
        background-color: $cf;

        span {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          border-radius: 4px;
          background-color: $secondary;
        }
      }
    }
  }
}

.job-modal_111 {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  border-radius: 5px;
}

.swiper-111 {
  height: 16px;
  margin-left: initial;
  color: $c55;

  .swiper-slide { cursor: pointer; }
}
