@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.table-101010 {
  width: 100%;
  display: flex;
  margin-top: 10px;

  .left-10 {
    display: inline-flex;
    flex-direction: column;

    p {
      display: inline-flex;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 22px;
        margin-bottom: 1px;
        font-size: 12px;
        font-weight: 500;
        color: $fff;
        background-color: $c999;
      }

      &:first-child {
        span { border-radius: 4px; }
      }
      &:nth-child(2) {
        span { border-radius: 4px 4px 0 0; }
      }
      &:last-child {
        span {
          margin-bottom: 0;
          border-radius: 0 0 4px 4px;
        }
      }
    }
    .title-00 {
      margin-bottom: 8px;
      border-bottom: 1px solid $ccc;
    }
  }
  .right-10 {
    width: 100%;
    display: flex;
    flex-direction: column;

    .right-101 {
      display: inline-flex;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // width: 140px;
        flex: 1;
        height: 22px;
        border-radius: 4px;
        margin-bottom: 1px;
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
      }
      .text-11 {
        color: $fff;
        background-color: #CFCFCF;
      }
      .up-00 {
        @include mont-font();
        @include error-color(1);
        background-color: #FBE8E8;
      }
      .down-00 {
        @include mont-font();
        @include success-color(1);
        background-color: #E5F9F3;
      }
      .text-1010 {
        @include white-bg(1);
      }
    }
    .title-00 {
      margin-bottom: 8px;
      border-bottom: 1px solid $ccc;

      .text-11 {
        &:first-child { background-color: #CFCFCF; }
        &:nth-child(2) { @include secondary-bg(1); }
        &:nth-child(3) { @include primary-bg(1); }
      }
    }
  }
}
