@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.table-555 {
  @include white-bg(1);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;

  .content-101 {
    display: inline-flex;
  }
  .content-103 {
    display: inline-flex;
    flex-direction: column;
  }
  .row-101 {
    @include primary-bg(0.1);
    display: inline-flex;
    flex-direction: column;

    &:first-child {
      flex: 1;
      background-color: $f7;
    }
    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      border-right: 1px solid $fff;
    }
    &:nth-child(2) { flex: 1; }
    &:nth-child(3), &:nth-child(4) { flex: 3; }
    strong {
      @include black-color(1);
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-width: 120px;
      min-height: 36px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }
    .line-101 {
      display: inline-flex;

      b {
        flex: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        height: 36px;
        line-height: 1;
        font-size: 12px;
        font-weight: 400;
        border-top: 1px solid $fff;
        color: $c55;

        &:nth-child(2), &:nth-child(3),
        &:nth-child(4), &:nth-child(5) {
          border-left: 1px solid $fff;
        }
      }
    }
  }
  .row-103 {
    display: inline-flex;

    span {
      @include mont-font();
      min-width: 120px;
      flex: 1;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 14px;
      font-weight: 400;
      // border-right: 1px solid $secondary;
    }
    // .row-10301 { @include pingfang-font(); }
    &:nth-child(even) {
      span { background-color: $f7; }
    }
  }
}
