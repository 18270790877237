@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";
@import './var.scss';

.table-head-1616 {
  display: inline-flex;
  padding-bottom: 1px;
  border-bottom: 1px solid $primary;

  strong {
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    font-size: 12px;
    line-height: 1;
    color: $fff;
    background-color: $primary;
  }
  .strong-01 {
    width: $industryWidth;
    margin-left: 0;
    border-radius: 4px 0 0 4px;
  }
  .strong-02 { width: $categoryWidth; }
  .strong-03 {
    width: $positionWidth;
    border-radius: 0 4px 4px 0;
  }
  .title-1616 {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    margin-left: 1px;

    b {
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 1;
      border-radius: 4px 4px 0 0;
      color: $fff;
      background-color: $primary;
    }
    span {
      flex: 1;
      display: inline-flex;
      margin-top: 1px;

      i {
        @include primary-bg(0.5);
        flex: 1;
        // width: $valueWidth;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 1px;
        font-size: 12px;
        line-height: 1;
        color: $fff;
      }
      .i-01 {
        margin-left: 0;
        border-bottom-left-radius: 4px;
      }
      .i-02 { @include primary-bg(0.8); }
      .i-03 {
        border-bottom-right-radius: 4px;
      }
    }
  }
}
