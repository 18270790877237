@charset 'UTF-8';

@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

/* Slider */
.slick-loading .slick-list {
  background: $fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: 400;
  font-style: normal;

  src: url('./fonts/slick.eot');
  src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
  @include black-bg(0.19);
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  margin-top: -60px;
  z-index: 99;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 60px;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover {
  @include primary-bg(0.4);
}
.slick-prev { left: -5px; }
.slick-prev:before {
  @include white-color(0.8);
  content: '<';
  font-size: 14px;
  // color: $fff;
}
.slick-next { right: -5px; }
.slick-next:before {
  @include white-color(0.8);
  content: '>';
  font-size: 14px;
  // color: $fff;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  height: 20px;

  display: flex !important;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;
  display: flex;
  margin: 0 2px;
  padding: 0;
  cursor: pointer;

  .custome-pagi_0051 {
    @include black-bg(0.1);
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: $black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: $primary;
}
.slick-dots li.slick-active {
  .custome-pagi_0051 {
    @include primary-bg(1);
    display: inline-flex;
    width: 20px;
    height: 8px;
    border-radius: 4px;
  }
}
