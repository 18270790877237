@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.sider-20 {
  @include white-bg(1);
  position: fixed;
  // top: 420px;
  left: 40px;
  display: flex;
  flex-direction: column;
  min-height: 490px;
  padding: 10px 0;
  z-index: 555;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  &.narrow-20 {
    width: 80px;
  }
  &.wide-20 {
    width: 220px;
    bottom: 30px;
  }
  .sider-21 {
    flex: 1;
    position: relative;
    display: flex;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
  }

  .sider-26 {
    width: 100%;
    display: flex;
    flex-direction: column;

    .head-30 {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
    }
    .title-5055 {
      display: inline-flex;
      flex-direction: column;

      .item-551 {
        display: inline-flex;
        flex-direction: column;
      }
    }
  }

  .sider-555 {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    b {
      display: inline-flex;
      width: 7px;
      height: 7px;
      border-radius: 4px;
      border: 1px solid $ec;
      background-color: $f7;

      &.active { background-color: $primary; }
    }
  }
}
