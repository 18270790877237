@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.title-1610 {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  height: 50px;

  .line-11 {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }
    span {
      @include other-color(0.25);
      font-size: 30px;
      font-weight: 600;
      margin-left: 8px;
      line-height: 1;
    }
  }
  .line-13 {
    display: inline-flex;
    flex-direction: column;

    .text-13 {
      font-size: 24px;
      line-height: 1;
      font-weight: 600;
      color: $c333;
      display: flex;
      align-items: center;
      i {
        @include mont-font;
        display: block;
        height: 18px;
        line-height: 18px;
        background: $d4;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        color: $white;
        margin-left: 10px;
        padding: 0 3px;
      }
    }
    .bar-13 {
      position: relative;
      display: inline-flex;
      height: 4px;
      width: 257px;
      margin-top: 8px;
      background-color: rgba(204, 204, 204, 0.25);

      i,
      b {
        position: absolute;
        top: 0;
        display: inline-flex;
        width: 12px;
        height: 100%;
      }
      i {
        left: 0;
        background-color: $c333;
      }
      b {
        left: 12px;
        background-color: rgba(51, 51, 51, 0.5);
      }
    }
  }
}
