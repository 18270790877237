@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.setting-model-warp {
  background: $white;
  width: 620px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 20px 20px;
  height: 604px;
  .top-search {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .no-result {
    height: 416px;
    padding: 20px;
    background-color: $f7;
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h4 {
      font-size: 16px;
      font-weight: 500;
      color: $c55;
      line-height: 22px;
      margin-bottom: 20px;
    }
    p {
      font-weight: 500;
      color: $c55;
      opacity: 0.3;
    }
    span {
      margin-top: 18px;
      color: $success;
    }
    .sugucess {
      width: 132px;
      height: 36px;
      background: $white;
      border-radius: 5px;
      border: 1px solid $primary;
      font-weight: 400;
      color: $primary;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      margin: 20px 0;
    }
  }

  .set-content {
    height: 416px;
    padding: 20px;
    overflow-y: auto;
    background-color: $f7;
    margin-top: 10px;
    border-radius: 5px;
    .items {
      display: flex;
      justify-content: space-between;
      margin-top: 23px;
      &:nth-child(1) {
        margin-top: 0;
      }
      i {
        color: $c55;
        font-weight: 500;
        margin-left: 10px;
      }
      .right {
        color: $cf;
      }
    }
  }

  .change-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .cancel {
      width: 100px;
      height: 36px;
      border-radius: 5px;
      border: 1px solid $ec;
      font-weight: 400;
      color: $c87;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
    .canok {
      width: 132px;
      height: 36px;
      background: $white;
      border-radius: 5px;
      border: 1px solid $primary;
      font-weight: 400;
      color: $primary;
      text-align: center;
      line-height: 36px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
