@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.category-fresh-salary_10 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  background-color: $f7;

  .one-1161 {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  .one-1191 {
    display: inline-flex;
    align-items: center;
  }
  .one-11 {
    display: inline-flex;

    strong {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
    .tag-01 {
      @include secondary-bg(0.1);
      @include secondary-color(1);
      display: inline-flex;
      justify-content: center;
      height: 20px;
      padding: 0 6px;
      margin: 0 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 3px;
    }
  }
  .one-13 {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
  }
}
