@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.data-model-warp {
  @include white-bg(1);
  position: absolute;
  width: 460px;
  left: 50%;
  top: 50%;
  margin-top: -180px;
  margin-left: -220px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  .model-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $ec;
    font-weight: 600;
    color: $black;
    font-size: 16px;
    span {
      background-color: $da;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      cursor: pointer;
      border-radius: 50%;
    }
  }
  .content-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    .input-group {
      background-color: $f7;
      display: flex;
      width: 140px;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      border-radius: 5px;
      color: $black;
    }
    p {
      width: 110px;
      font-weight: 500;
      color: $black;
    }
    span {
      font-size: 12px;
      font-weight: 600;
      margin-left: 10px;
      color: $error;
    }
  }
  .modle-bot {
    width: 170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .sure {
      display: flex;
      width: 70px;
      height: 36px;
      align-items: center;
      color: $white;
      background-color: $secondary;
      border-radius: 5px;
      justify-content: center;
      cursor: pointer;
    }
    .cancel {
      display: flex;
      width: 68px;
      height: 34px;
      align-items: center;
      color: $black;
      background-color: $white;
      border-radius: 5px;
      justify-content: center;
      border: 1px solid $ec;
      cursor: pointer;
    }
  }
}
