@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.wealfare-page-warp {
  .tops-title-warp {
    // display: flex;
    // justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    .wealfare-title-111 {
      width: calc(100% - 300px);
    }
    p {
      color: $c87;
      margin-top: 8px;
      font-size: 12px;
    }
  }
  .tops-title-warps {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
    .wealfare-title-111 {
      width: calc(100% - 300px);
    }
    // p {
    //   color: $c87;
    //   margin-top: 8px;
    //   font-size: 12px;
    // }
  }
  .update-time {
    color: $c87;
    font-size: 12px;
    margin-bottom: 8px;
    display: block;
    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }
}
