@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

* {
  margin: 0;
  padding: 0;
}

.dash-move:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.common-progress {
  .progress-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    b {
      @include mont-font();
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $ec;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      color: $c55;
      line-height: 16px;
      margin-top: 4px;
    }
    p {
      font-size: 14px;
      color: $black;
      white-space: nowrap;// 文字不换行
      overflow: hidden;// 超出隐藏
      text-overflow: ellipsis;
      width: 96px;
    }
    .liner-show {
      flex: 1;
      padding: 0 10px;
    }
    .numbers {
      @include mont-font();
      display: flex;
      align-items: flex-end;
      margin-top: 4px;
      color: $c55;
      font-weight: 600;
      font-size: 12px;
      min-width: 60px;
      justify-content: flex-end;
    }
  }
}

.dashboard-page_100 {
  display: flex;
  flex-direction: column;

  .dashboard-111 {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }
}
