@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.tab-1100 {
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 5px;
  background-color: $fff;
}
