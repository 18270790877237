@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.stuff-trend_10 {
  .trend-151 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
