@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.huring-revenue-warp {
  .huring-content {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    .huring-content-item {
      text-align: center;
      border-right: 1px solid $ec;
      &:last-child {
        border-right: 0;
      }
      .title {
        margin-bottom: 20px;
        font-weight: 600;
        color: $black;
        font-size: 20px;
      }
      .huring-chart {
        width: 100%;
        height: 400px;
      }
    }
  }
  .huring-banner {
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, $a186e 0%, $a1987 100%);
    border-radius: 8px;
    margin-top: 20px;
    color: $white;
    font-size: 40px;
    text-align: center;
    line-height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    img {
      cursor: pointer;
    }
  }
}
