@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.title-1100 {
  display: inline-flex;

  i {
    display: inline-flex;
    width: 4px;
    height: 20px;
    border-radius: 2px;
    background-color: $secondary;
  }
  strong {
    margin-left: 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
  }
}
